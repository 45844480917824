<template>
  <div id="schedule">
    <!-- button -->
    <b-button
      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
      variant="outline-primary"
      @contextmenu.prevent="$refs.menu.open"
    >
      Right click on me
    </b-button>

    <!-- context -->
    <vue-context ref="menu">
      <li>
        <b-link
          v-for="data in menuData"
          :key="data.text"
          class="d-flex align-items-center"
          @click="optionClicked(data.text,data.icon)"
        >
          <feather-icon
            :icon="data.icon"
            size="16"
          />
          <span class="ml-75">{{ data.text }}</span>
        </b-link>
      </li>
    </vue-context>

    <b-card no-body>
      <b-card-body>
        <b-row>
          <b-col
            cols="12"
            md="6"
            class="mb-md-0 mb-2"
          >
            <label>Clinic</label>
            <v-select
              v-model="clinic"
              :options="clinicOptions"
              class="w-100"
              :reduce="name => name.code"
              label="name"
              placeholder="เลือกคลินิก"
            />
          </b-col>
          <b-col
            cols="12"
            md="6"
            class="mb-md-0 mb-2"
          >
            <label>Doctor</label>
            <v-select
              v-model="doctor"
              :options="doctorOptions"
              class="w-100"
              :disabled="clinic === ''"
              :reduce="name => name.code"
              label="name"
              placeholder="เลือกแพทย์"
            />
          </b-col>
        </b-row>
        <b-row class="mt-1">
          <b-col class="text-right">
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="outline-primary"
              size="sm"
              class="mr-1"
              @click="showCreateScheduleForm"
            >
              <feather-icon
                icon="PlusCircleIcon"
                class="mr-50"
              />
              <span class="align-middle">Create</span>
            </b-button>
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="outline-danger"
              size="sm"
              @click="showDeleteScheduleForm"
            >
              <feather-icon
                icon="MinusCircleIcon"
                class="mr-50"
              />
              <span class="align-middle">Delete</span>
            </b-button>
          </b-col>
        </b-row>
        <div class="mt-1 app-calendar overflow-hidden border">
          <div class="row no-gutters">
            <!-- Calendar -->
            <div class="col position-relative">
              <div class="card shadow-none border-0 mb-0 rounded-0">
                <div class="card-body pb-0">
                  <full-calendar
                    ref="scheduleCalendar"
                    :options="calendarOptions"
                    class="full-calendar"
                  />
                  <hr>
                </div>
              </div>
            </div>
          </div>
        </div>
      </b-card-body>
    </b-card>
    !-- Modal Create Schedule -->
    <b-modal
      id="formCreateScheduleModal"
      hide-footer
      centered
      size="lg"
      title="Create Schesule"
    >
      <form-create-schedule
        @exit="onCloseModal"
      />
    </b-modal>
    !-- Modal Dekete Schedule -->
    <b-modal
      id="formDeleteScheduleModal"
      hide-footer
      centered
      size="lg"
      title="Delete Schesule"
    >
      <form-create-schedule
        @exit="onCloseModal"
      />
    </b-modal>
  </div>
</template>

<script>
import FullCalendar from '@fullcalendar/vue'
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from '@fullcalendar/interaction'
import timeGridPlugin from '@fullcalendar/timegrid'
import listPlugin from '@fullcalendar/list'
import VueContext from 'vue-context'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import FormCreateSchedule from './FormCreateSchedule.vue'

const date = new Date()
const nextDay = new Date(new Date().getTime() + 24 * 60 * 60 * 1000)

export default {
  components: {
    FullCalendar,
    VueContext,
    vSelect,
    FormCreateSchedule,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      clinic: '',
      doctor: '',
      clinicOptions: [],
      doctorOptions: [],
      menuData: [
        { icon: 'PlusIcon', text: 'New' },
        { icon: 'FileIcon', text: 'Open' },
        { icon: 'SaveIcon', text: 'Save' },
        { icon: 'SaveIcon', text: 'Save As' },
        { icon: 'XIcon', text: 'Close' },
      ],
      value: '',
      drcode: '',
      drcodeID: '',
      calendarOptions: {
        plugins: [dayGridPlugin, interactionPlugin, timeGridPlugin, listPlugin],
        initialView: 'dayGridMonth',
        headerToolbar: {
          start: 'sidebarToggle, prev,next, title',
          // end: 'dayGridMonth,timeGridWeek,timeGridDay,listMonth',
        },
        events: [
          {
            id: 1,
            url: '',
            title: 'Design Review',
            start: date,
            end: nextDay,
            allDay: false,
            extendedProps: {
              calendar: 'Business',
            },
          },
          {
            id: 2,
            url: '',
            title: 'Meeting With Client',
            start: new Date(date.getFullYear(), date.getMonth() + 1, -11),
            end: new Date(date.getFullYear(), date.getMonth() + 1, -10),
            allDay: true,
            extendedProps: {
              calendar: 'Business',
            },
          },
        ],
        eventColor: '#FFFFFF',
        dateClick: this.handleDateClick,
      },
      isCalendarOverlaySidebarActive: true,
      isEventHandlerSidebarActive: false,
      scheduleList: [],
      schedulesColor: {
        Sun: 'color: red',
        Mon: 'color: #f7d794',
        Tue: 'color: #f78fb3',
        Wed: 'color: #9AECDB',
        Thu: 'color: #e77f67',
        Fri: 'color: #3dc1d3',
        Sat: 'color: #786fa6',
      },
      MeaningList: [
        {
          name: 'ตารางนัดยังไม่เต็ม',
          color: this.MeaningColorBackground('NotFull'),
        },
        {
          name: 'ตารางนัดเต็มแล้ว',
          color: this.MeaningColorBackground('Full'),
        },
        {
          name: 'นัดเกินจำนวนที่กำหนด',
          color: this.MeaningColorBackground('Over'),
        },
        {
          name: 'แพทย์งดออกตรวจบางเวลา',
          color: this.MeaningColorBackground('SomePeriod'),
        },
        {
          name: 'แพทย์งดออกตรวจทั้งวัน',
          color: this.MeaningColorBackground('AllDay'),
        },
        {
          name: 'ไม่มีคนไข้ในตารางออกตรวจ',
          color: this.MeaningColorBackground('Blank'),
        },
        {
          name: 'ไม่มีตารางออกตรวจหรือการนัด',
          color: this.MeaningColorBackground('No'),
        },
      ],
    }
  },
  created() {
    this.drcodeID = this.$route.params.doctorid
    this.getScheduleByDoctor()
    this.getPatientInMonth()
  },
  // mounted() {
  //   window.addEventListener('keydown', e => {
  //     // console.log(e)
  //   })
  // },
  methods: {
    optionClicked(text, icon) {
      // console.log(text, icon)
      // this.$toast({
      //   component: ToastificationContent,
      //   props: {
      //     title: `You have click on ${text}!`,
      //     icon,
      //   },
      // })
    },
    CheckKey(e) {
      // console.log(e.keyCode)
      // if (e.keyCode === 13) {
      // }
      // return
    },
    handleDateClick(arg) {
      // @contextmenu.prevent="$refs.menu.open"
      // this.$refs.menu.open
      // console.log(arg)
      // this.$refs.menu
      // this.$refs.menu.open
    },
    handleMonthChange(arg) {
      // console.log(arg)
    },
    eventTextColor(arg) {
      // console.log(arg)
    },
    MeaningColorBackground(type) {
      const calendarsColor = {
        NotFull: '#ffeaa7',
        Full: '#fab1a0',
        Over: '#d63031',
        SomePeriod: '#81ecec',
        AllDay: '#74b9ff',
        Blank: '#FFFFFF',
        No: '#b2bec3',
      }
      return calendarsColor[type]
    },
    next() {
      this.$refs.scheduleCalendar.next()
      // console.log('Next')
    },
    changeView(view) {
      this.$refs.scheduleCalendar.fireMethod('changeView', view)
      // console.log('View')
    },
    getScheduleByDoctor() {
      this.$http({
        url: '/api/DoctorSchedule/getallDocSchedule',
        method: 'POST',
        data: {
          DoctorCodeID: this.drcodeID,
        },
      }).then(({ data }) => {
        // // console.log(data)
        const result = []
        let scheduleDay = ''
        let scheduleTime = []
        const totalData = data.length - 1
        for (let i = 0; i < data.length; i += 1) {
          if (scheduleDay !== data[i].day) {
            // // console.log(scheduleDay, scheduleTime)
            if (scheduleDay !== '') {
              result.push({ day: scheduleDay, period: scheduleTime })
              // // console.log(result)
            }
            scheduleDay = data[i].day
            scheduleTime = []
          }
          scheduleTime.push({
            period: data[i].period,
            periodFrom: data[i].periodFrom.substr(0, 5),
            periodEnd: data[i].periodEnd.substr(0, 5),
            followup: data[i].followup,
            walkin: data[i].walkin,
            isActive: data[i].isActive,
          })
          if (i === totalData) {
            result.push({ day: scheduleDay, period: scheduleTime })
          }
        }
        this.scheduleList = result
      }).catch(error => {
        // console.log(error)
      })
    },
    getPatientInMonth() {
      this.$http({
        url: '/api/DoctorSchedule/getScheduleDetail',
        method: 'POST',
        data: {
          DocCodeID: this.drcodeID,
          month: 2,
          year: 2021,
        },
      }).then(({ data }) => {
        // // console.log(data)
        const result = []
        for (let i = 0; i < data.length; i += 1) {
          if (data[i].listData !== null) {
            const scheduledata = data[i].listData[0]
            // const colorSchedule = '#28c76f'
            let backgroundSchedule = '#FFFFFF'
            const currentDate = new Date().toJSON().slice(0, 10)
            if (data[i].date === currentDate) {
              backgroundSchedule = '#f8f8f8'
            }
            result.push({
              title: `${scheduledata.appointment} / ${scheduledata.followup}`,
              date: data[i].date,
              backgroundColor: backgroundSchedule,
            })
          }
        }
        this.calendarOptions.events = result
      }).catch(error => {
        // console.log(error)
      })
    },
    showCreateScheduleForm() {
      this.$bvModal.show('formCreateScheduleModal')
    },
    showDeleteScheduleForm() {
      this.$bvModal.show('formDeleteScheduleModal')
    },
    onCloseModal(id) {
      // console.log(id)
      this.$bvModal.hide(id)
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/apps/calendar.scss";
@import "@core/scss/vue/libs/vue-context.scss";

.fc-event-main {
  float: right;
  // font-size: 1.2em;
}
</style>

<template>
  <div id="create-schedule">
    <b-form ref="form_create_schedule">
      <b-row>
        <b-col
          cols="6"
        >
          <label>Clinic</label>
          <v-select
            v-model="clinic"
            :options="clinicOptions"
            class="w-100"
            :reduce="name => name.code"
            label="name"
            placeholder="เลือกคลินิก"
          />
        </b-col>
        <b-col
          cols="6"
        >
          <label>Doctor</label>
          <v-select
            v-model="doctor"
            :options="doctorOptions"
            class="w-100"
            :disabled="clinic === ''"
            :reduce="name => name.code"
            label="name"
            placeholder="เลือกแพทย์"
          />
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <label for="startDate">Start date</label>
          <b-form-datepicker
            id="startDate"
            v-model="startDate"
            :min="curdate"
            :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
          />
        </b-col>
        <b-col>
          <label for="endDate">End date</label>
          <b-form-datepicker
            id="endDate"
            v-model="endDate"
            :min="curdate"
            :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
          />
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <label for="startTime">Start Time</label>
          <v-select
            v-model="startTime"
            :options="startTimeOptions"
          />
        </b-col>
        <b-col>
          <label for="endTime">End Time</label>
          <v-select
            v-model="endTime"
            :options="endTimeOptions"
          />
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <label for="amountOfPatient">Amount of Patient</label>
          <b-form-input
            id="amountOfPatient"
            v-model="amountOfPatient"
            type="number"
          />
        </b-col>
      </b-row>
      <b-card no-body>
        <b-card-body>
          <h6>Clinic: {{ clinic }} Doctor: {{ doctor }}</h6>
          <h6>Start: {{ startDate }}</h6>
          <h6>End: {{ endDate }}</h6>
          <h6>{{ startTime }} - {{ endTime }}</h6>
          <h6>Amount of Patient: {{ amountOfPatient }}</h6>
        </b-card-body>
      </b-card>
      <hr>
      <b-row>
        <b-col
          cols="12"
          class="text-center"
        >
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            type="button"
            variant="outline-secondary"
            class="mr-1"
            size="sm"
            @click="resetForm"
          >
            Cancel
          </b-button>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            type="button"
            variant="primary"
            size="sm"
            @click="createSchedule"
          >
            Create
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </div>
</template>

<script>
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    vSelect,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      curdate: new Date(),
      clinic: '',
      doctor: '',
      clinicOptions: [],
      doctorOptions: [],
      startDate: null,
      endDate: null,
      startTime: '',
      endTime: '',
      startTimeOptions: [],
      endTimeOptions: [],
      amountOfPatient: 0,
    }
  },
  methods: {
    resetForm() {
      this.$emit('exit', 'formScheduleModal')
    },
    createSchedule(e) {
      e.preventDefault()
      // // console.log('Create Schedule')
      this.$swal({
        title: 'Schedule Updated.',
        icon: 'success',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.$emit('exit', 'formScheduleModal')
        }
      })
    },
  },
}
</script>

<style>

</style>
